
$base-plum: #53213E;
$base-black: #333;
$hover-black: #555;
$bluish: #4A7794;
$light-grey:  #e9ecef;
$seaform: #6FACA5;
$lightpink: #ffb6c163;
$darkgreen: #34493A;
$max-width: 1200px;
$transition: 0.5s;
$font-1:'Raleway', sans-serif;
$font-2: 'Quicksand', sans-serif;

.App{
  h1, h2, h3{
    font-family: $font-1;
  }
  h1{
    font-size: 3rem;
  }
  p.lead{
    font-size: 1.5rem;
  }
  p, a{
    font-family: $font-2;
    font-weight: 400;
    font-size: 1.3rem;
    color: #000;
  }
}
.home-navigation{
  list-style: none;
  padding: 0;
  li{
    display: inline-block;
    padding: 25px;
    a{
      color: #fff;
      padding: 3px 15px 5px;
      transition: $transition;
      border-bottom: 2px solid white;
      text-decoration: none;
      &:hover{
        background: $base-plum;
        color: white;
        text-decoration: none;
        border-bottom: none;
      }
    }
  }
}
.navigation{
  background-color: transparent;
  padding: 5px 10px;
  text-align: right;
  position: absolute;
  width: 100%;
  div{
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    ul{
      list-style: none;
      padding: 0;
      li{
        display: inline;
        .nav-link{
          padding: 3px 15px 5px;
          margin-right: 4px;
          text-decoration: none;
          color: #fff;
          display: inline-block;
          font-size: 20px;
          transition: $transition;
          &:hover{
            background: $base-plum;
            color: white;
          }
          &.active{
            border-bottom: 3px solid #fff;
          }
        }
      }
    }
    
  }
}
.tech-images{
    background-color: $light-grey;
    padding: 15px;
    margin-bottom: 15px;
  div{
    max-width: 140px;
    display: inline-block;
    padding: 10px 20px;
    p{
      text-align: center;
      font-weight: 400;
      font-size: 1.3rem;
    }
  }
}
img{
  max-width: 100%;
}
.main-body{
  margin: auto;
  max-width: $max-width;
  padding: 0 20px;
  min-height: 50vh;
  padding-top: 50px;
  #about{
    hr{
      width: 30%;
      border-top: 4px solid $base-plum;
      margin-top: 0;
      margin-bottom: .5rem;
    }
    img{
      display: block;
      margin: 0 auto 20px;
    }
    h3{
      margin: 20px 0;
      background-color: $base-plum;
      color: #fff;
      padding: 8px;
    }
    #about-links {
      margin: 40px 0;
      a {
        background-color: $darkgreen;
        padding: 10px 15px;
        color: white;
        font-size: 1.4rem;
        border: solid 2px white;
        display: block;
        text-align: center;
        text-decoration: none;
        &:hover{
          border: solid 2px $darkgreen;
          text-decoration: none;
        }
      }
      
  }
  }
  #contact{
    text-align: center;
    hr{
      width: 100%;
      border-top: 2px solid $light-grey;
      margin-top: 0;
      margin-bottom: .5rem;
    }
    .col-md-4 img{
      max-width: 150px;
      display: block;
      margin: 20px auto;
      transition: $transition;
      &:hover{
        transition: $transition;
        transform: scale(1.09);
      }
    }
    
  }
}

.other-heading, .App-header{
  background-image: url(./images/ferns.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}
.other-heading{
  height: 160px;
  div{
    padding: 100px 20px 0;
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
  }
}
.App-header {
  height: 100vh;
  padding: 0 20px;
  > div{
    text-align: center;
    border: solid 3px #fff;
    max-width: $max-width;
    padding: 50px;
    margin: 50px auto;
    h1{
      margin: 0;
      font-weight: 100;
    }
  }
  div div a img{
    margin: 5px 15px;
    transition: $transition;
    &:hover{
      transition: $transition;
      transform: scale(1.09);
    }
  }
}
.projects {
  h1{
    background: $base-plum;
    padding: 20px;
    color: white;
    margin-bottom: 20px;
  }
  .project-each.col-md-6:first-of-type, .project-each.col-md-12:first-of-type {
    > div{
      border-left: 6px solid $base-plum;
      padding-left: 20px;
    }
  }
  .project-each.col-md-6:nth-of-type(2) {
    > div{
      border-right: 6px solid $base-plum;
      padding-right: 20px;
    }
  }
  .project-each.col-md-12{
    padding-right: 30px;
    padding-left: 30px;
  }
  .project-each.col-md-6 > div, .project-each.col-md-12 > div{
      margin-bottom: 20px;
      padding: 15px;
      -webkit-box-shadow: 0 6px 10px 0 rgba(0,0,0,0.34), 0 1px 18px 0 rgba(0,0,0,0.32), 0 3px 5px -1px rgba(0,0,0,0.3);
      box-shadow: 0 6px 10px 0 rgba(0,0,0,0.34), 0 1px 18px 0 rgba(0,0,0,0.32), 0 3px 5px -1px rgba(0,0,0,0.3);
    .tech-section{
      background-color: $light-grey;
      padding: 15px;
      margin-bottom: 15px;
      text-align: center;
      p{
        margin-bottom: 0;
        font-size: 1.2rem;
      }
    }
    p{
      text-align: center;
      font-size: 1.2rem;
    }
    h3, a{
      background-color: $base-plum;
      color: #fff;
      font-size: 20px;
      padding: 5px 0 8px 0;
      text-align: center;
      display: block;
      text-decoration: none;
    }
    a{
      border: 2px solid #fff;
      margin-bottom: 10px;
      font-weight: 500;
      &:hover{
        text-decoration: none;
        border: 2px solid $base-plum;
      }
    }
    h2.project-title{
      text-align: center;
      font-size: 2.2rem;
      color: $darkgreen;
      padding: 5px 0 8px 0;
      border-bottom: 2px solid $darkgreen;
    }
    img{
      display: block;
      margin: auto;
      margin-bottom: 10px;
      &.sample-image{
        border: solid 1px lightgrey;
      }
    }
  }
  .projects-nav{
    text-align: center;
    button{
      background: transparent;
      border: none;
      -webkit-appearance: button;
      font-size: 34px;
      color: $base-plum;
      padding: 0 20px;
      margin: 0 8px 20px;
      border-bottom: 3px solid transparent;
    }
    button.active-projects{
      background-color: $base-plum;
      color: #fff;
    }
    button:hover{
      border-bottom: solid 3px $base-plum;
      transition: $transition;
    }
  }
}

@media only screen and (min-width: 768px) {
  .App-header > div {
    margin: 200px auto 0;
  }
}
@media only screen and (max-width: 768px) {
  .projects .project-each.col-md-6 {
      > div{
        border-left: 6px solid $base-plum;
        border-right: 6px solid $base-plum;
      }
    }
}
@media only screen and (max-width: 600px) {
  .main-body{
    padding-top: 20px;
  }
  .home-navigation li {
    display: inline-block;
    padding: 10px;
  }
  .navigation div{
    padding: 0 10px;
    ul li .nav-link{
      font-size: .9rem;
      padding: 3px 4px 5px;
      margin-right: 2px;
    }
  }
  .other-heading{
    height: 120px;
   div {
    padding: 70px 20px 0;
   }
  }
  .projects .projects-nav button {
    font-size: 1.1rem;
    padding: 0px 18px;
    margin: 0 2px 10px;
  }
  .App{
    h1{
      font-size: 1.8rem;
    }
    h2{
      font-size: 1.5rem;
      margin-top: 10px;
    }
    h3{
      font-size: 1.4rem;
    }
    p.lead {
      font-size: 1.2rem;
    }
    p, .projects .project-each.col-md-6 > div p, .projects .project-each.col-md-12 > div p {
      font-size: 1rem;
    }
  }
  .projects .project-each.col-md-6 > div h2.project-title, .projects .project-each.col-md-12 > div h2.project-title {
    font-size: 1.6rem;
  }
  .projects .project-each.col-md-6 > div h3, .projects .project-each.col-md-6 > div a, .projects .project-each.col-md-12 > div h3, .projects .project-each.col-md-12 > div a {
    padding: 5px 5px 8px 5px;
    font-size: 1rem;
  }
  
}



/*runform*/
#paceyourself{
  text-align: center;
    background-color: #282c34;
    padding: 0 20px 20px; 
  h1, h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-top: 50px;
    font-family: 'Permanent Marker', cursive;
    background: transparent;
    margin: 0;
    padding: 0;
  }
  img{
    width: 25%;
    padding-top: 10px;
  }
  button, label, select, input{
    font-size: 20px;
    font-family: 'Orbitron', sans-serif;
    -webkit-appearance: none;
  }
  button, input[type="submit"]{
      background-color: $seaform;
      border: solid 3px #282c34;
      padding: 10px 15px;
      margin: 10px 10px;
      display: inline-block;
      color: #282c34
  }
  input[type="text"]{
    width: 100%;
  }
  button:hover, input[type="submit"]:hover{
    border: solid 3px $seaform;
  }
  .required-alert{
    background-color: #EFCD69;
    width: 200px;
    margin: 10px auto;
    padding: 3px 3px 5px;
  }
  .required-alert p{
    margin: 0;
    font-family: 'Orbitron', sans-serif;
  }
  label{
    color: white;
    display: inline-block;
    margin: 20px 10px 10px 0;
  }
  .result{
    background-color: #C4C0B4;
    max-width: 595px;
    padding: 20px;
    margin: auto;
  }
  .result h2 {
    margin: 0;
    color: #111;
    padding: 0;
  }
  .result h2 span{
    color: #E64B15;
    font-family: 'Permanent Marker', cursive;
    font-size: 48px;
  }
}
